import type { FC } from 'react';
import { useEffect } from 'react';
import type { ISbApiResult, ISbStoriesParams, ISbStoryData } from '@storyblok/js';
import { getStoryblokApi, StoryblokComponent, useStoryblokState } from '@storyblok/react';
import type { GetStaticProps } from 'next';


type PageProps = {
  sbData: {
    story: ISbStoryData | null;
    key: number | null;
  }
}

const Custom404: FC<PageProps> = ({ sbData }) => {
  const storyData = useStoryblokState(sbData.story);

  useEffect(() => {
    const clickHandler = (evt: Event) => {
      evt.preventDefault();
      window.history.back();
    };
    const goBackAnchor = document.querySelector('a[href="#back"]');
    if (goBackAnchor) {
      goBackAnchor.addEventListener('click', clickHandler);
    }

    return () => {
      if (goBackAnchor) {
        goBackAnchor.removeEventListener('click', clickHandler);
      }
    };
  }, []);

  return (
    <StoryblokComponent blok={ storyData?.content } />
  );
};

export const getStaticProps: GetStaticProps<PageProps> = async () => {
  const slug = 'not-found';

  const storyblokApi = getStoryblokApi();

  const sbParams: ISbStoriesParams = {
    version: `${ process.env.STORY_BLOK_VERSION }` as ISbStoriesParams['version'],
    resolve_relations: 'global_reference.reference',
  };

  try {
    const { data }: ISbApiResult = await storyblokApi.get(`cdn/stories/${ slug }`, sbParams);

    return {
      props: {
        sbData: {
          story: data ? data.story : null,
          key: data ? data.story.id : null,
        },
      },
    };
  } catch {
    return { props: { sbData: { story: null, key: null }}};
  }
};

export default Custom404;
